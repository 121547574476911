import { getRojoDependencyClass, rojoCustomElement } from '../../lib/dependencies';

const parseJSONResponse = (r: Response) => {
    return r.json().then((body) => ({ status: r.status, body }));
};

getRojoDependencyClass('rojo-page').then((pageClass) => {
    class CommentCount extends pageClass.RojoElement {
        get storyId() {
            return this.getAttribute('story-id');
        }

        getCommentCount() {
            const commentCountEndpoint = `/api/commentcount/${this.storyId}`;
            fetch(commentCountEndpoint)
                .then(parseJSONResponse)
                .then(({ status, body }) => {
                    if (200 === status) {
                        if (body.count > 1) {
                            this.innerHTML = `${body.count} Comments`;
                        }
                        if (body.count === 1) {
                            this.innerHTML = `${body.count} Comment`;
                        }
                    }
                });
        }

        override connectedCallback() {
            super.connectedCallback();
            this.getCommentCount();
        }
    }

    rojoCustomElement('rojo-comment-count', CommentCount);
});
